import "element-plus/lib/components/message-box/style/css";
import _ElMessageBox from "element-plus/lib/components/message-box";
import BackgroundVideo from '@/components/backgroundVideo.vue'; // import industryCard from '@/components/industryCard.vue';

import industryInstructions from '@/components/industryInstructions.vue'; // import industryHighlightText from '@/components/industryHighlightText.vue';

import mynFeatures from '@/components/mynFeatures.vue';
import '@/components/industry.css';
export default {
  name: 'Mining',
  data: function data() {
    return {
      prestring: '<div class="additional"><div class="user-card"> <small class="stats" style:"color:white; font-size:0.25rem> ⛏</small></div><div class="more-info"><h1>Advantages</h1><div class="coords">',
      poststring: '<span class="more">Mouse over the card for more info</span> </div> </div> </div>'
    };
  },
  components: {
    BackgroundVideo: BackgroundVideo,
    industryInstructions: industryInstructions,
    // industryHighlightText,
    mynFeatures: mynFeatures
  },
  methods: {
    interMine: function interMine() {
      _ElMessageBox.alert(' <div class="center"> <div class="card black"> <div class="additional"> <div class="user-card"> <small class="stats" style:"color:white; font-size:0.25rem> ⛏</small></div> <div class="more-info"> <h1>Advantages</h1> <div class="coords"> <span>&bull;Portable (can move from site to site)<br>&bull;Low-Data Mode<br>&bull;Ad-Hoc quality checking </span></div> </div> </div> <div class="general"> <h1>Demand Planning</h1> <p> Understand the rate of extraction through real time visibility via the Admin Panel. As goods move throughout the production process every mutation is stored on the blockchain, allowing for a higher resolution of detail for the AI model to analyse.</span> </div> </div> </div>', {
        dangerouslyUseHTMLString: true
      });
    },
    truck: function truck() {
      _ElMessageBox.alert(" <div class=\"center\"> <div class=\"card purple\"> <div class=\"additional\"> <div class=\"user-card\"> <small class=\"stats\" style:\"color:white; font-size:0.25rem> \u26CF</small></div> <!-- <div class=\"more-info\"> <h1>Advantages</h1> <div class=\"coords\"> <span>Group Name</span> <span>Joined January 2019</span> </div> <div class=\"coords\"> <span>Position/Role</span> <span>City, Country</span> </div> <div class=\"stats\"> <div> <div class=\"title\">Awards</div> <i class=\"fa fa-trophy\"></i> <div class=\"value\">2</div> </div> <div> <div class=\"title\">Matches</div> <i class=\"fa fa-gamepad\"></i> <div class=\"value\">27</div> </div> <div> <div class=\"title\">Pals</div> <i class=\"fa fa-group\"></i> <div class=\"value\">123</div> </div> <div> <div class=\"title\">Coffee</div> <i class=\"fa fa-coffee\"></i> <div class=\"value infinity\">\u221E</div> </div> </div> </div> --> </div> <div class=\"general\"> <h1>Tool </h1> <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a volutpat mauris, at molestie lacus. Nam vestibulum sodales odio ut pulvinar.</p> ".concat(this.poststring), {
        dangerouslyUseHTMLString: true
      });
    },
    lst: function lst() {
      _ElMessageBox.alert("  <div class=\"center\"><div class=\"card\"> ".concat(this.prestring, "<span>&bull;Portable (can move from site to site)<br>&bull;Low-Data Mode<br>&bull;Ad-Hoc quality checking </span></div></div></div><div class=\"general\"><h1>Road Transport</h1><p> Use Myneral to view the location of your road transports in real time and gain understanding of better routes, materials carried and centralised reporting. MynOnes cellular connectivity enables easy centralised reporting.</span></div></div></div>"), {
        dangerouslyUseHTMLString: true
      });
    },
    dump: function dump() {
      // `event` is the native DOM event
      _ElMessageBox.alert(" <div class=\"center\"> <div class=\"card green\"> <div class=\"additional\"> <div class=\"user-card\"> <small class=\"stats\" style:\"color:white; font-size:0.25rem> \u26CF</small></div> <!-- <div class=\"more-info\"> <h1>Advantages</h1> <div class=\"coords\"> <span>Group Name</span> <span>Joined January 2019</span> </div> <div class=\"coords\"> <span>Position/Role</span> <span>City, Country</span> </div> <div class=\"stats\"> <div> <div class=\"title\">Awards</div> <i class=\"fa fa-trophy\"></i> <div class=\"value\">2</div> </div> <div> <div class=\"title\">Matches</div> <i class=\"fa fa-gamepad\"></i> <div class=\"value\">27</div> </div> <div> <div class=\"title\">Pals</div> <i class=\"fa fa-group\"></i> <div class=\"value\">123</div> </div> <div> <div class=\"title\">Coffee</div> <i class=\"fa fa-coffee\"></i> <div class=\"value infinity\">\u221E</div> </div> </div> </div> --> </div> <div class=\"general\"> <h1>Real-time Cloud Access</h1> <p>Gain quick and easy access to real time statistics from anywhere in the world. Our easy to use 'Admin Panel' also allows for the generation of rich reports.</p> ".concat(this.poststring), {
        dangerouslyUseHTMLString: true
      });
    },
    extract: function extract() {
      _ElMessageBox.alert(' <div class="center"><div class="card green"><div class="additional"><div class="user-card"> <small class="stats" style:"color:white; font-size:0.25rem> ⛏</small></div> <div class="more-info"><h1>Advantages</h1><div class="coords"> <span>&bull;Portable<br>&bull;Integrates with on-site infrastructure<br>&bull;Low/No Data mode with LoRaWAN</span> </div> </div></div><div class="general"><h1>Extraction (Bulk goods)</h1><p> As minerals are extracted from the earth, bulk tokens are issued based on the weight of the specific item being tracked. These tokens are then passed from MynOne to MynOne.</p> <span class="more">Mouse over the card for more info</span> </div></div></div>', {
        dangerouslyUseHTMLString: true
      });
    },
    building: function building() {
      _ElMessageBox.alert(' <div class="center"><div class="card purple"><div class="additional"><div class="user-card"> <small class="stats" style:"color:white; font-size:0.25rem> ⛏</small></div> <div class="more-info"><h1>Advantages</h1><div class="coords"> <span>&bull;Losses during processing stages can be stored on the blockchain<br>&bull;Integrates with existing infra<br>&bull;Ad-Hoc quality checking </span></div> </div></div><div class="general"><h1>Processing</h1><p> After minerals have been extracted and have been transported to the processing site, they are again scanned into the Myneral.me system.At this point any changes to the item can be recorded on the blockchain, as well as any rich metadata accompanying the process.</p> <span class="more">Mouse over the card for more info</span> </div></div></div>', {
        dangerouslyUseHTMLString: true
      });
    }
  }
};